    /**
     * @returns {boolean} Check if product set is available
     */
    function isProductSetavailable() {
        if ($('.product-set-detail').length) {
            return true;
        }
        return false;
    }

    /**
     * Updates the quantity is disabled or not
     * @param {boolean} qtyStatus - update quantity disable
     * @param {jQuery} $productContainer - DOM element for a given product
     */
    function handleQuantityDropdownstatus(qtyStatus, $productContainer, quickViewContainerId) {
        var isQuickViewModal =  $(document).find(quickViewContainerId).length > 0 ? true : false;
        var $qtySelectorOptions = quickViewContainerId && isQuickViewModal ? $(quickViewContainerId + ' .quantity-dropdown option') : $('.quantity-dropdown option');
        if (qtyStatus) {
            $qtySelectorOptions.eq(0).attr('selected', '');
        }
        $(quickViewContainerId && isQuickViewModal ?  quickViewContainerId + ' .quantity-dropdown select' : '.quantity-dropdown select').attr('disabled', qtyStatus); 
    }

    // Function to sanitize and validate input
    function sanitizeInput(input) {
        return $('<div>').text(input).html();
    }
    /**
     * Updates the quantity DOM elements post Ajax call
     * @param {Object} product - Response product
     */
    // eslint-disable-next-line
    function updateQuantities(product, $productContainer, pid, quickViewContainerId) {
        var minOrderQuantity = product.minOrderQuantity;
        var maxOrderQuantity = product.maxOrderQuantity;
        var maxQuantityAllowed = product.maxQuantityAllowed;
        var isQuickViewModal =  $(document).find(quickViewContainerId).length > 0 ? true : false;
        var quantities = product.quantities;
        let isSelectedQuantityExceedsMaxQuantity = product.selectedQuantity;
        var optionHTMLdefault = '<option class="dropdown-item" id="select-variantQty-0" value="0"></option>';
        var optionsHtml = '';
        var sanitizedQuickViewContainerId = sanitizeInput(quickViewContainerId);
        var sanitizedPid = sanitizeInput(pid);
        var $qtySelector = quickViewContainerId && isQuickViewModal ? $(sanitizedQuickViewContainerId + ' select.quantity-dropdown-' + sanitizedPid) : $('select.quantity-dropdown-' + pid);

        for (var i = 0; i < quantities.length; i++) {
            if (quantities[i].value >= minOrderQuantity && quantities[i].value <= maxOrderQuantity && quantities[i].value <= maxQuantityAllowed) {
                optionsHtml += `<option class="dropdown-item" data-url="${quantities[i].url}"  data-value="${quantities[i].value}" id="select-variantQty-${quantities[i].value}" value="${quantities[i].value}">${quantities[i].value}</option>`;
            }
        }

        $qtySelector.empty().html(optionsHtml);

        if (isProductSetavailable()) {
            $productContainer.find(`.quantity-dropdown option[value=${product.selectedQuantity}]`).attr('selected', 'selected');
        } else {
            if (product.selectedQuantity > product.maxQuantityAllowed) {
                isSelectedQuantityExceedsMaxQuantity = product.maxQuantityAllowed;
            }
            $(`${quickViewContainerId && isQuickViewModal ? quickViewContainerId : ''} .quantity-dropdown option[value=${isSelectedQuantityExceedsMaxQuantity}]`).attr('selected', 'selected');
        }

        var qtyDisabled = false;
        product.variationAttributes.forEach(elem => {
            if (elem.displayValue === '') {
                qtyDisabled = true;
            }
        });
        var isOneSize = product.variationAttributes[1].values[0].id.toLowerCase() === 'one size' || product.variationAttributes[1].values[0].id.toLowerCase() === 'onesize';
        if (isOneSize) {
            qtyDisabled = false;
        }
        if (qtyDisabled) {
            $qtySelector.empty().html(optionHTMLdefault);
            handleQuantityDropdownstatus(true, $productContainer, quickViewContainerId);
        } else {
            handleQuantityDropdownstatus(false, $productContainer, quickViewContainerId);
        }
        if (isSelectedQuantityExceedsMaxQuantity !== product.selectedQuantity) {
            $('#select-variantQty-' + isSelectedQuantityExceedsMaxQuantity).click();
        }
    }

    module.exports = {
        updateQuantities,
        isProductSetavailable
    };
