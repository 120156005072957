var utils = require('../utils');

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */
function quickviewSlick() {
    $('.js-quickview-slick').on('init', function(event, slick) {
        if (slick && slick.slideCount !== undefined && slick.slideCount !== null) {
            $('.slide-count').text('1/' + slick.slideCount); // Initialize slide count
        }
    }).slick({
        autoplay: false,
        rows: 0,
        speed: 300,
        dots: false,
        mobileFirst: true,
        infinite: false,
        accessibility: true,
        variableWidth: true,
        arrows: false,
        responsive: [{
            breakpoint: 992,
            settings: 'unslick'
        }]
    }).on('afterChange', function(event, slick, currentSlide) {
        console.log('Changed to slide ' + currentSlide);
        // Update slide count
        $('.slide-count').text((currentSlide + 1) + '/' + slick.slideCount);
    });
}

/**
 * @param {Object} product - response object
 */
function updateImages(product) {
    var imgResponse = '';
    product.images.large.forEach((item, index) => {
        imgResponse += '<div class="' + (index === 0 ? 'active' : '') + '">' +
            utils.renderPictureTag(item, {devices: {desktop: product.imageDimentions.desktop.product,mobile: product.imageDimentions.mobile.product}, className:"quickview-slick-img"}) +
            '</div>';
    });
    if ($('.js-quickview-slick').hasClass('slick-initialized')) {
        $('.js-quickview-slick').slick('removeSlide', null, null, true);
    } else {
        $('.js-quickview-slick').html('');
    }
    $('.js-quickview-slick').slick('slickAdd', imgResponse);

    // update slide count
    if ($('.js-quickview-slick').slick('getSlick') && $('.js-quickview-slick').slick('getSlick').slideCount !== undefined && $('.js-quickview-slick').slick('getSlick').slideCount !== null) {
        $('.slide-count').text('1/' + $('.js-quickview-slick').slick('getSlick').slideCount); // Initialize slide count
    }
}

module.exports = {
    quickviewSlick,
    updateImages
};
