var base = require('base/product/base');
var quantityHelper = require('../quantitySelectorHelper');
var utils = require('../utils');
var tealiumEnable = require('int_tealium_sfra/tealiumEnable');

// eslint-disable-next-line
function deskColabImgs(clbImgs, imgSizeObj, clbImage, colabImg) {
    if (clbImgs.length > 0) {
        // eslint-disable-next-line
        clbImage = `<div class="product-image col-12">
        ${utils.renderPictureTag(clbImgs[0], { devices: { mobile: imgSizeObj.desktop.main }, className: 'd-block', isColab: true })}
            </div>`;
        colabImg.parent().addClass('has-image');
        colabImg.append(clbImage);
    }
}

// eslint-disable-next-line
function handleProductImageCls(productImageCls) {
    if (quantityHelper.isProductSetavailable()) {
        // eslint-disable-next-line
        productImageCls = 'col-xl-12';
    } else {
        // eslint-disable-next-line
        productImageCls = 'col-xl-6';
    }
    return productImageCls;
}

/**
 * Add class in zoomModal to for slide to selected slide.
 */
function addZoomModalClass() {
    $(document).on('click', '.swiper', function () {
        $('.swiper').removeClass('zoomModalClass');
        $(this).addClass('zoomModalClass');
        var imageSrc = $(this).find('source') ? $(this).find('source')[0].getAttribute('srcset') : '';
        if (utils.getCurrentBreakpoint() !== 'mobile') {
            tealiumEnable.getAnalyticsData('handleProductImageEvent', imageSrc, 'zoomModal');
        }
    });
}

/**
 * Dynamically updates zoom modal from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {Object[]} imgSizeObj - Array of image dimensions
 */
function updateZoomModal(imgs, imgSizeObj) {
    var zoomModal = $('#productImagesModal');
    // var layoutImgMob = zoomModal.find('.zoomed-images--mob');
    var layoutImgDesk = zoomModal.find('.zoomed-images--desk');
    var deskThumb = layoutImgDesk.find('.image-thumbnails');
    var deskImgs = layoutImgDesk.find('.image-large');
    var imgThumb;
    var imgDesk;

    deskThumb.empty();
    deskImgs.empty();

    for (var j = 0; j < imgs.length; j++) {
        imgThumb = '<div class="product-image--thumb col-12" data-index="' + j + '" ><a href="#' + j + '" class="large-scroll" target=_self>' + utils.renderPictureTag(imgs[j], { devices: { desktop: imgSizeObj.desktop.zoomSmall, mobile: imgSizeObj.desktop.zoomSmall }, lazyload: true }) + '</a></div>';
        deskThumb.append(imgThumb);
    }

    for (var k = 0; k < imgs.length; k++) {
        imgDesk = '<div class="product-image--large col-12" data-index="' + k + '" ><a id="' + k + '">' + utils.renderPictureTag(imgs[k], { devices: { desktop: imgSizeObj.desktop.zoomLarge, mobile: imgSizeObj.desktop.zoomLarge }, lazyload: true }) + '</a></div>';
        deskImgs.append(imgDesk);
    }

    if ($('.product-set-detail').length) {
        utils.lazyLoadImage(zoomModal);
    }

    zoomModal.off('show.bs.modal').on('show.bs.modal', function () {
        utils.lazyLoadImage(zoomModal);
    });
}

export default {
    ...base,
    addZoomModalClass: addZoomModalClass,

    addToCart: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
            return;
        });
    },

    onProductAfterAttributeSelect: function () {
        $('body').on('product:afterAttributeSelect', (event, args) => {
            const response = args.data;
            var imgParams = response.product.imageDimentions;
            var primaryImageUrls = response.product.images.large;
            updateZoomModal(primaryImageUrls, imgParams);
        });
    },

    imgCaroSelection: function () {
        var imgModal = $('#productImagesModal');
        var imgTile = imgModal.find('.product-image');
        var index;

        imgTile.on('click', function () {
            var colab = $('.product-image__wrapper .product-image').find('img').data('iscolab');

            index = $(this).data('index');

            if (colab) {
                index += 1;
            }
            try {
                document.querySelector('.zoomModalClass').swiper.slideTo(index, 0);
            } catch (err) {
                imgModal.modal('hide');
            }
        });
    },

    zoomModalAnimate: function () {
        var $modal = $('#productImagesModal');
        $modal.on('hide.bs.modal', function (e) {
            if ($modal.hasClass('show')) {
                e.stopPropagation();
                e.preventDefault();
                $modal.removeClass('show');
                setTimeout(() => $modal.modal('hide'), 300);
            }
        });
    },


    // TODO : Move this to better place
    selectedSwiper: function () {
        var gotoIndex;
        $(document).on('click', '.product-images--primary .product-image', function () {
            try {
                const swiperMain = document.querySelector('.swiper').swiper;
                if ($(this).data('image-index') !== undefined) {
                    gotoIndex = $(this).data('image-index');
                } else {
                    gotoIndex = $(this).find('img').data('imageindex');
                }
                const zoomImageContainer = document.querySelector('.zoomed-images--mob');
                if (zoomImageContainer && zoomImageContainer.swiper) {
                    const swiperInstance = zoomImageContainer.swiper;
                    if (swiperInstance) {
                        swiperInstance.slideTo(gotoIndex, 0);
                    }
                    swiperMain.zoom.out(); // Zoom out to the initial state
                }
            } catch (error) {
                console.error(error);
            }
        });

    }
};

