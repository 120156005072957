import utils from '../utils';
const constants = require('../constants');
var quickViewHelper = require('../product/quickViewHelper');
var quickViewHelperSlick = require('../helpers/quickviewSlickHelper');
var tealiumEnable = require('int_tealium_sfra/tealiumEnable');
var productGlobalVariable;
var quickViewContainerId = '#quickViewModal';
import 'base_global_css/components/productImage.scss';
import 'base_global_css/components/modal.scss';

/**
 * One Size product identification
 *
 * @param {string} sizeVariation - to find out product is one size or not
*  @return {boolean} - boolean for one size product identification.
 */
function isOneSizeProduct(sizeVariation) {
    return sizeVariation.id.toLowerCase() === 'one size' || sizeVariation.id.toLowerCase() === 'onesize';
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement(id) {
    var htmlString =
    `
    <div class="modal slide-in" id="${id}" data-recomtype="" role="dialog"><span class="enter-message sr-only"></span>
        <div class="modal-dialog quick-view-dialog quickview__modal">
            <!-- Modal content-->
            <div class="modal-content quickview__modal--content">
                <div class="modal-close"> <button type="button" class="close" data-dismiss="modal"> <span aria-hidden="true" class="icon-close" aria-label="Close"></span> <span class="sr-only"> </span> </button></div>
                <div class="modal-carousel-set"></div>
                <div class="modal-body quickview__modal__body"></div>
            </div>
        </div>
    </div>`;
    $('body').append(htmlString);
}

function getModalHtmlElementSet(id) {
    var htmlString =
    `
    <div class="modal slide-in" id="${id}" data-recomtype="" role="dialog"><span class="enter-message sr-only"></span>
            <div class="modal-dialog quick-view-dialog quickview__modal">
                <!-- Modal content-->
                <div class="modal-content quickview__modal--content">
                    <div class="modal-close"> <button type="button" class="close" data-dismiss="modal"> <span
                                aria-hidden="true">&times;</span> <span class="sr-only"> </span> </button></div>
                    <div class="modal-carousel-set"></div>
                    <div class="modal-carousel"></div>
                    <div class="modal-header quickview__modal__head">
                        <div class="row"></span>
                            <div class="product-detail__imgbadges quickvie-head col-12">Pre-order</div>
                            <h1 class="quickview__modal__head--name"></h1>
                        </div>
                    </div>
                    <div class="modal-body quickview__modal__body"></div>
                    <div class="quickview__modal__footer"></div>
                </div>
            </div>
        </div>`;
        $('body').append(htmlString);
}


 /**
  * @typedef {Object} QuickViewHtml
  * @property {string} body - Main Quick View body
  * @property {string} footer - Quick View footer content
  */
 function quickviewSlick() {
    setTimeout(function () {
        $('.js-quickview-slick').slick({
            autoplay: false,
            rows: 0,
            speed: 300,
            dots: false,
            infinite: false,
            accessibility: true,
            variableWidth: true,
            slidesToShow: 1.7,
            slidesToScroll: 1,
            arrows: true,
            prevArrow:
                '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style=""><svg class="svg-arrow left" width="10.3" height="7.53" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.79889 0.238281L5.57358 0.238281L2.43633 3.37554L10.3333 3.37553V4.63044L2.43633 4.63044L5.57358 7.76769L3.79889 7.76769L0.0341797 4.00299L3.79889 0.238281Z" fill="black"/> </svg></button>',
            nextArrow:
                '<button class="slick-next slick-arrow slick-disabled" aria-label="Next" type="button" aria-disabled="true" style=""><svg class="svg-arrow right" width="10.3" height="7.53" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.20116 7.76769H5.42646L8.56371 4.63044L0.666748 4.63044L0.666748 3.37554L8.56371 3.37554L5.42646 0.238282L7.20116 0.238281L10.9659 4.00299L7.20116 7.76769Z" fill="black"/></svg></button>'
        });
    }, 150);
}

/**
 * Update Badges
 *
 * @param {Object} responseData - Data response from AJAX call
 */
function updateBadges(responseData, quickViewContainerId) {
    var siteData = $('.page').data('sitename');
    var isOneSize = isOneSizeProduct(responseData.variationAttributes[1].values[0]);
    var availabilityValue = '';
    var preOrderStatus = '';
    var availabilityMessages = responseData.availability.messages;
    var isAvailability = responseData.available;
    var imgbadges = '';
    var stockBadges = '';
    var saleBadges = '';
    var availabilityMessageExists = false;
    var responseMessage = responseData.badgeSkuLevelStatus.preOrderMessage;
    var responseMessageDate = responseData.badgeSkuLevelStatus.preOrderMessageDate;
    if (responseData.badgeSkuLevelStatus.preOrderStatus) {
        preOrderStatus += '<div class="pre-order"> <span>' + responseMessage + '</span> <span class="pre-order-date">' + responseMessageDate + '</span> </div>';
    }

    availabilityMessages.forEach(function (message) {
        if (responseData.badgeSkuLevelStatus.availabilityStatus) {
            if (isAvailability) {
                availabilityValue += `<li><div class="${isAvailability ? '' : 'custom-error'}">${message}</div></li>`;
            }
        }
    });

    availabilityMessageExists = availabilityValue && (responseData.readyToOrder || isOneSize);

    if (utils.isTHSite()) {
        if (responseData.brand) {
            imgbadges += responseData.brand;
        } else if (responseData.subBrand) {
            imgbadges += responseData.subBrand;
        }
    } else {
        if (responseData.badgeSkuLevelStatus.newBadge) {
            imgbadges += responseData.badgeSkuLevelStatus.newBadge;
            imgbadges += responseData.badgeSkuLevelStatus.imageBadge1 || responseData.badgeSkuLevelStatus.imageBadge2 ? ' , ' : '';
        }

        if (responseData.ImagebadgeSkuLevelStatus.imageBadge1) {
            imgbadges += responseData.ImagebadgeSkuLevelStatus.imageBadge1;
        } else if (responseData.ImagebadgeSkuLevelStatus.imageBadge2) {
            imgbadges += responseData.ImagebadgeSkuLevelStatus.imageBadge2;
        } else if (responseData.ImagebadgeSkuLevelStatus.imageBadge3) {
            imgbadges += responseData.ImagebadgeSkuLevelStatus.imageBadge3;
        }
    }

    if (!responseData.badgeSkuLevelStatus.availabilityStatus && responseData.availability.inStockValue.length === 0) {
        stockBadges += '';
    } else if (responseData.availability.inStockValue.length !== 0) {
        stockBadges += `<div class="product-detail__salebadges-text inventory-msg"> ${responseData.availability.inStockValue} </div>`;
    }

    if (responseData.badgeSkuLevelStatus.finalSalestatus) {
        saleBadges += '<div class="custom-error product-detail__salebadges-text quickview__modal__body--finalBadge">Final Sale</div>';
    }
    if (responseData.readyToOrder || isOneSize) {
        $(quickViewContainerId + ' .availability-msg').removeClass('error').empty().html(availabilityValue);
    } else {
        $(quickViewContainerId + ' .availability-msg').removeClass('error').empty().html('');
    }
    $(quickViewContainerId + ' .preorder-status').empty().html(preOrderStatus);
    $(quickViewContainerId + ' .product-detail__imgbadges').empty().html(imgbadges);
    $(quickViewContainerId + ' .product-detail__stockBadges').empty().html(stockBadges);
    $(quickViewContainerId + ' .product-detail__salebadges').empty().html(saleBadges);

    var showFullDetailLink = true;

    // utils.setVariationAttrMargin($(quickViewContainerId + ' .attribute-item', quickViewContainerId + ' .product-quickview').last(), showFullDetailLink || stockBadges || saleBadges || availabilityMessageExists);
}


/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    if ($html.find('.quickViewBody').length) {
        var body = $html.find('.quickViewBody');
        return { body: body };
    } else {
        var body = $html.find('.product-quickview');
        var carousel = $html.find('.quickview__modal__images');
        var addToCart = $html.find('.desk-footer .cart-and-ipay');
        var footer = addToCart;
        return { body: body, footer: footer, carousel: carousel };
    }
}

/**
 * render Quick view component
 * @param {Object} data ajax response data
 * @param {jQuery} $container jQuery container
 */
function renderQuickView(data, $container, recommendationContainerType) {
    var parsedHtml = parseHtml(data.renderedTemplate);
    $('.quickview__modal__body', $container).empty();
    $('.quickview__modal__body', $container).html(parsedHtml.body); // $container is the context
    var $setCarousel = $('.set-items-carousel');
    if ($setCarousel.length) {
        $('.modal-carousel').html(parsedHtml.carousel);
        $('.quickview__modal__footer', $container).html(parsedHtml.footer);
    }
    if ($setCarousel.length && !$('#quickViewModal').hasClass('show')) {
        $('.modal-carousel-set').html($('.set-items-carousel').html());
        $container.addClass('quickview-set');
        $('.modal-carousel-set .set-items-carousel__product').removeClass('selected enabled');
        window.PVH.mountAllComponents('quickView');
    } else if ($setCarousel.length) {
        $('.quickview-set .quickview__modal--content').addClass('stay-open');
    }

    if (!data.product.orderable) {
        $('.sales .value', $container).html(constants.messages.OOS_status);
    }
    $('.full-pdp-link', $container).text(data.quickViewFullDetailMsg);
    $('.full-pdp-star-rating-link', $container).attr('href', data.productUrl);

    $('.full-pdp-link', $container).attr('href', data.productUrl);
    $('h1.quickview__modal__head--name', $container).html(
        '<a class="quickview__modal-heading-link product-name" href=' + data.product.selectedProductUrl + '>' + data.product.productName + '</a>'
    );
    $('.size-chart', $container).attr('href', data.productUrl);
    $('.modal-header .close .sr-only', $container).text(
        data.closeButtonText
    );
    $('.enter-message').text(data.enterDialogMessage);
    const $quickviewModal = $container;
    utils.openModal($quickviewModal);
    if ($setCarousel.length) {
        quickviewSlick();
    } else {
        quickViewHelperSlick.quickviewSlick();
    }
    productGlobalVariable = data.product;
    updateBadges(data.product, quickViewContainerId);
    quickViewHelper.updateProductURL(data.product, $('#quickViewModal'));
    if (recommendationContainerType && recommendationContainerType !== '') {
        $('#quickViewModal').data('recomtype', recommendationContainerType);
    }
    $('body').trigger('quickview:ready');
    if ($setCarousel.length) {
        $('#quickViewModal').spinner().stop();
        $('.modal-carousel-set .set-items-carousel__product').removeClass('selected');
        let $clickedElm = $('.modal-carousel-set .set-items-carousel__product[data-pid="' + data.product.id + '"]');
        if ($clickedElm.length) {
            $clickedElm.addClass('selected');
            $clickedElm[0].scrollIntoView({ block: 'center' });
        }
    }
    setTimeout(function () {
        if (!$('.stay-open').length) {
            $quickviewModal.find('.close').trigger('focus');
        }
    }, 500);
    // tealium event
    if (recommendationContainerType && recommendationContainerType !== '') {
        tealiumEnable.getAnalyticsData('quickViewEvent', data, 'ctlQuickView', '', recommendationContainerType);
    } else if ($setCarousel.length) {
        tealiumEnable.getAnalyticsData('quickViewEvent', data, 'pdpBundle', '', recommendationContainerType);
    }

    PVH.mountAllComponents('StickyContainer');

    $(window).on('resize', function () {
        if ($setCarousel.length) {
            quickviewSlick();
        } else {
            quickViewHelperSlick.quickviewSlick();
        }
    });

    $('#quickViewModal').on('hidden.bs.modal', function () {
        $(this).remove();
    });
}

export default {
    renderQuickView,
    parseHtml,
    updateBadges,
    getModalHtmlElement,
    getModalHtmlElementSet,
    productGlobalVariable
};
